<template>
  <div class="page-cache">
    <h1 class="text-h3" style="margin-top: 20px">
      Сброс кэша
    </h1>

    <ul class="page-cache__sections">
      <li
        v-for="section in sections"
        :key="section.name"
      >
        <h2
          class="text-h5"
          style="margin-bottom: 12px"
        >
          {{ section.title }}
        </h2>
        <v-btn
          @click="section.clean"
          :color="section.color"
          depressed
          :disabled="section.loading"
        >
          <v-icon left small>mdi-broom</v-icon>
          <span>
            {{ section.loading ? (section.label + '...') : section.label }}
          </span>
        </v-btn>
      </li>
    </ul>


  </div>
</template>

<script>
import systemService from '../services/system'

const types = {
  MAIN: 'main',
  BOOKING: 'booking'
}

export default {
  data () {
    return {
      types,
      loading: {
        [types.MAIN]: false,
        [types.BOOKING]: false,
      }
    }
  },
  computed: {
    sections () {
      return [
        {
          title: 'Общий',
          label: 'Сбросить',
          name: types.MAIN,
          clean: () => this.clean(types.MAIN, systemService.clearCache),
          loading: this.loading[types.MAIN],
          color: 'primary',
          visible: true,
        },
        {
          title: 'Модели расписания',
          label: 'Сбросить',
          name: types.BOOKING,
          clean: () => this.clean(types.BOOKING, systemService.clearCacheBooking),
          loading: this.loading[types.BOOKING],
          color: 'error',
          visible: true,
        }
      ].filter(({ visible }) => visible)
    }
  },
  methods: {
    async clean (field, action) {
      let error
      if (this.loading[field]) return
      this.loading[field] = true
      try {
        await action()
      } catch (e) {
        error = e
      }
      this.loading[field] = false
      if (error) {
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-cache {
  padding-bottom: 60px
}

.page-cache__sections {
  margin: 0;
  padding: 0;
  list-style: none;
  & > * {
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid #eee;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
